<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Restorative']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Restorative" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"This area of dentistry broadly refers to the following treatments: fillings, veneers, crowns and bridges. We also provide more complex treatment for toothwear, oral rehabilitation and aesthetic improvement such as composite bonding."
			],
			cards:[
				{
					'title': 'Crowns & Bridges',
					'content': 'These are fixed restorations that are custom-made by the local laboratory that will fit over your existing prepared tooth. They will reinforce and strengthen your remaining tooth structure. During the preparation appointment we will need to create an impression of your tooth for the laboratory to construct the work. A bridge may be a suitable solution for missing teeth, as we use adjacent teeth (abutments) to secure the fitting. All treatment will be carried out to the highest standard to ensure we have created the exact result for you.'
				},
				{
					'title': 'Veneers',
					'content': 'There are instances where teeth may be built up using composite or porcelain veneers. The preparation of the teeth is minimal or even has no preparation (depending on the supporting tooth). The aim of this treatment is to improve function and aesthetics and can be very effective. Your dentist will explain all the options to you.'
				},
        {
          'title': 'Fillings',
          'content': 'We use filling material to treat dental decay, replace old fillings, improve toothwear and malformations on teeth. There are different options of filling material depending on the situation and your dentist will guide you through their own recommendations.'
        }
			]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		.card:nth-last-child(n):nth-child(odd) {
			margin-right: auto;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>